import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import "../../assets/styles/acerca.scss"


export default function Acerca() {
  return (
    <div className="page-acerca">
      <div className="container">
        <div className="title-container">
          <h1>Bienvenidos a UNISERSE</h1>
          <h3>donde estamos comprometidos contigo, con tu familia, ¡con tu vida!</h3>
        </div>
        <div className="content-wrapper">
          <div>
            <p>Somos un aliado líder en la administración de riesgos, gracias a más de 50 años generando confianza y seguridad con hechos.</p>
            <p>En UNISERSE abordamos los riesgos de nuestros clientes como una oportunidad para generar impactos operativos y de economías en sus programas de Seguros, logrando así mejorar la posición competitiva.</p>
            <p>Nuestra razón de ser persigue tener relaciones de largo plazo con nuestros clientes, a través de un servicio percibido como de calidad y de cercanía</p>
            <h3>NUESTROS VALORES</h3>
            <ul>
              <li>Espíritu de servicio.</li>
              <li>Pasión y orden en todo lo que emprendemos.</li>
              <li>Respeto en todas las relaciones que mantenemos.</li>
              <li>Creatividad e Innovación que nos permita aprovechar oportunidades para generarle mejoras a nuestros clientes.</li>
            </ul>
          </div>
          <div>
            <h2>HISTORIA</h2>
            <p>Nuestra historia esta estrechamente ligada con el desarrollo del mercado asegurador costarricense y con las diferentes etapas que este ha tenido en su evolución de un mercado de monopolio al mercado abierto que hoy tenemos</p>
            <p>UNISERSE es producto de la visión de un hombre de origen humilde, trabajador, esforzado, disciplinado, visionario, enamorado de su familia, de sus clientes y de su profesión, llamado Carlos Benavides Méndez
            (qdDg), quien desde 1968 inspirado en un viaje de formación sueña con la empresa que por décadas construyó y dejó como legado al mercado asegurador costarricense.</p>
            <StaticImage
                src={"../../assets/images/uniserse-desk.jpg"}
                alt="Experiencia"
                width={640}
              />
          </div>
        </div>
      </div>
      <div className="blk-formula">
        <div className="container">
          <h2>Fórmula Uniserse</h2>
          <ul>
            <li>
              <StaticImage
                src={"../../assets/images/ico-contract.png"}
                alt="Experiencia"
                width={60}
              />
              <h4>Experiencia</h4>
              <div className="excerpt">En el desarrollo, implementación y administración de programas de seguros para empresas e individuos.</div>
            </li>
            <li>
              <StaticImage
                src={"../../assets/images/ico-knowledge.png"}
                alt="Innovación y Flexibilidad"
                width={60}
              />
              <h4>Innovación y Flexibilidad</h4>
              <div className="excerpt">Para proveer soluciones reales y efectivas en el menor tiempo posible y de manera oportuna.</div>
            </li>
            <li>
              <StaticImage
                src={"../../assets/images/ico-search-person.png"}
                alt="Capacidad y Conocimiento"
                width={60}
              />
              <h4>Capacidad y Conocimiento</h4>
              <div className="excerpt">Para diseñar programas de seguros adecuados a las necesidades de nuestros clientes.</div>
            </li>
            <li>
              <StaticImage
                src={"../../assets/images/ico-group-people.png"}
                alt="Especialización"
                width={60}
              />
              <h4>Especialización</h4>
              <div className="excerpt">Por segmentos de negocio y capacidad de gestión de riesgos específicos.</div>
            </li>
            <li>
              <StaticImage
                src={"../../assets/images/ico-handshake.png"}
                alt="Relación de Confianza"
                width={60}
              />
              <h4>Relación de Confianza</h4>
              <div className="excerpt">Con aseguradoras y proveedores que nos permite entregar beneficios especiales a nuestros clientes.</div>
            </li>
            <li>
              <StaticImage
                src={"../../assets/images/ico-secure-doc.png"}
                alt="Operativa Completa"
                width={60}
              />
              <h4>Operativa Completa</h4>
              <div className="excerpt">En el manejo de los seguros, nos encargamos de todas las gestiones para la administración del programa.</div>
            </li>
            <li>
              <StaticImage
                src={"../../assets/images/ico-value.png"}
                alt="Independencia"
                width={60}
              />
              <h4>Independencia</h4>
              <div className="excerpt">Con una clara orientación a nuestros clientes y sus necesidades para acompañarlos en su negocio.</div>
            </li>
          </ul>
        </div>
      </div>
      <div className="blk-como-hacemos container">
        <h2>¿Cómo lo hacemos?</h2>
        <ul>
          <li>
            <div className="content">
              <StaticImage
                src={"../../assets/images/couple-contract.jpg"}
                alt="Analizamos y presentamos propuestas acertadas y transparentes"
              />
              <h4>Analizamos y presentamos propuestas acertadas y transparentes</h4>
              <p>
                Nuestro equipo interdisciplinario de vasta experiencia y conocimiento del mercado, escucha y canaliza sus necesidades de protección en procura de ofrecer un seguro o bien un plan de administración de riesgos justo a su medida.
                La relación que mantenemos con las aseguradoras, nos garantiza un flujo de trabajo sostenible y eficiente.
              </p>
            </div>
          </li>
          <li>
            <div className="content">
              <StaticImage
                src={"../../assets/images/adults-family.jpg"}
                alt="Administramos su riesgo de manera experta y empática"
              />
              <h4>Administramos su riesgo de manera experta y empática</h4>
              <p>
                Le acompañamos en la selección objetiva e informada de los seguros requeridos para la administración de sus riesgos, agilizando los procesos y trámites; asegurando la pronta consecución de pólizas.
                Monitoreamos permanentemente riesgos para anticiparnos a eventos y asegurar el cumplimiento de las condiciones de planes de seguros.
              </p>
            </div>
          </li>
          <li>
            <div className="content">
              <StaticImage
                src={"../../assets/images/bad-car.jpg"}
                alt="Administramos su riesgo de manera experta y empática"
              />
              <h4>Aseguramos un respaldo robusto e inmediato</h4>
              <p>En caso de presentarse un siniestro estaremos para acompañar y respaldarle durante todo el proceso, respondiendo con rapidez y eficacia, acompañando y monitoreando permanentemente el caso hasta su cierre.</p>
            </div>
          </li>
          <li>
            <div className="content">
            <StaticImage
                src={"../../assets/images/laptop.jpg"}
                alt="Administramos su riesgo de manera experta y empática"
              />
              <h4>Actuamos con flexibilidad</h4>
              <p>Nuestra independencia nos permite tomar decisiones flexibles a sus requerimientos, lo que nos permite ser innovadores para resolver necesidades.</p></div>
          </li>
        </ul>
      </div>
    </div>
  )
}
